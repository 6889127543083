<template>
  <v-row>
    <v-col cols="12" align="center" dense>
      <v-combobox
        label="Model"
        v-model="value"
        :items="items"
        variant="underlined"
      />
    </v-col>
    <v-col align="center" dense>
      <!-- Dynamically render the component based on the selected value -->
      <component :is="currentComponent" v-if="currentComponent" />
    </v-col>
    <!-- Tooltip displaying instructions based on the selected model -->
    <v-tooltip activator="parent" location="bottom">
      <div>
        <p v-for="(line, index) in tooltipContent" :key="index">{{ line }}</p>
      </div>
    </v-tooltip>
  </v-row>
</template>

<script>
import MovementArrive from '../components/Demo/MovementArrive.vue'
import SteeringComponent from '../components/Demo/SteeringComponent.vue'
import MovementWander from '../components/Demo/MovementWander.vue'
import MovementFlock from '../components/Demo/MovementFlock.vue'
import PathGame from '../components/Demo/PathGame.vue'

export default {
  name: 'ProjectDemo',
  components: {
    MovementArrive,
    SteeringComponent,
    MovementWander,
    MovementFlock,
    PathGame
  },
  data: () => ({
    items: ['Kinetic', 'Arrive', 'Wander', 'Flock', 'Path'],
    value: 'Kinetic',
  }),
  computed: {
    // Map the selected value to its corresponding component
    currentComponent() {
      const mapping = {
        'Arrive': MovementArrive,
        'Kinetic': SteeringComponent,
        'Wander': MovementWander,
        'Flock': MovementFlock,
        'Path': PathGame,
      }
      return mapping[this.value] || null
    },
    // Tooltip messages for each movement model
    tooltipContent() {
      const messages = {
        'Arrive': [
          'Please click anywhere on the canvas, and the boid will land and stop where you click.'
        ],
        'Kinetic': [
          'Please click anywhere on the canvas. The boid will move to your click position and then continue moving.'
        ],
        'Wander': [
          'Click anywhere within the canvas to toggle the visualization, and press any key to change the wandering behavior.'
        ],
        'Flock': [
          'Please click anywhere in the canvas to drop a Boid.',
          'Drag to continuously drop Boids.',
          'Dropping too many Boids may be computationally expensive.'
        ],
        'Path': [
          "The pathfinding algorithm uses Dijkstra's Shortest Path Algorithm.",
          "The Boid will follow the path until it reaches the end, where a decision tree determines the next destination.",
          'Grey-filled tiles indicate walls, yellow-filled tiles are generated by Dijkstra, and red-filled tiles are blocked.',
          'Click on any non-wall and non-blocked tile to trigger pathfinding. Clicking on a blocked area will teleport the Boid to a random destination.'
        ]
      }
      return messages[this.value] || []
    }
  }
}
</script>